import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226014184-d7b0bf58-1ea0-4ce7-b79a-f0bdacdb49de.png" />
    <h2>{`Usage`}</h2>
    <p>{`Use select to allow users to pick a single option from a dropdown menu of predetermined options.`}</p>
    <h3>{`States`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226014188-07ffaaaf-e5a8-4e63-930f-86f95f83c018.png" />
    <p><strong parentName="p">{`Focused:`}</strong>{` The input has been navigated to and is ready to accept input.`}</p>
    <p><strong parentName="p">{`Disabled:`}</strong>{` The input cannot accept input or become focused.`}</p>
    <p><strong parentName="p">{`Invalid:`}</strong>{` The value failed validation.`}</p>
    <p><strong parentName="p">{`Valid:`}</strong>{` The value passed validation.`}</p>
    <p>{`For more information about the "valid" and "invalid" states, see the `}<a parentName="p" {...{
        "href": "../ui-patterns/forms/overview#validation"
      }}>{`validation section`}</a>{` of the form design pattern guidelines.`}</p>
    <h3>{`Best practices`}</h3>
    <ul>
      <li parentName="ul">{`Use a select input for longer lists of options. If there are 6 or fewer options and you don't need to conserve vertical space, consider using a `}<a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`radio group`}</a>{` instead.`}</li>
      <li parentName="ul">{`Provide a default selected option when possible`}</li>
      <li parentName="ul">{`Order the menu options in a logical order to make it easier for users to find the option they're looking for. Default to alphabetical order.`}</li>
    </ul>
    <h2>{`Anatomy`}</h2>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226014190-e6c9f284-c3b7-4037-8967-3d2fe075a509.png" />
    <p><strong parentName="p">{`Label (required):`}</strong>{` A short title that labels the group of options and helps users understand the relationship between the options in the group`}</p>
    <p><strong parentName="p">{`Required indicator:`}</strong>{` Indicates that a selection is required`}</p>
    <p><strong parentName="p">{`Input (required):`}</strong>{` Displays the selected value and is used to toggle the dropdown menu`}</p>
    <p><strong parentName="p">{`Caption:`}</strong>{` May be used to provide additional context about the options when the label alone is not sufficient. Caption text should be as short as possible. Caption text may be displayed at the same time as a validation message, or it may be hidden if it only provides redundant information.`}</p>
    <p><strong parentName="p">{`Validation message:`}</strong>{` A single validation message may be displayed to provide helpful information for a user to complete their task. It is most commonly used to explain why a value is invalid so they can correct it and submit the form.`}</p>
    <h2>{`Options`}</h2>
    <h3>{`Sizes`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226014192-ccc2b121-2d94-4f1e-a3fe-2eab07369744.png" />
    <p>{`A text input can be made smaller or larger depending on the context it's being used in.`}</p>
    <p>{`You could make an input smaller if it is being used in a tight space with other small elements. You could make an input larger if it's in an open space or it feels out of proportion with adjacent large elements.`}</p>
    <h3>{`Width`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226014194-14b3273f-f16c-4795-bce7-a449eb5bb5ad.png" />
    <p>{`By default, a select input will be as wide as the text of it's longest option.`}</p>
    <p>{`You can render a select input to fill the width of it's parent element.`}</p>
    <h3>{`With a placeholder`}</h3>
    <img width="960" role="presentation" src="https://user-images.githubusercontent.com/2313998/226014196-d2cf9c41-3366-4c79-a56f-a8646a769f2b.png" />
    <p>{`You may show placeholder text when no option is selected. The placeholder option is hidden from the dropdown menu when a selection is required.`}</p>
    <h2>{`Interactions`}</h2>
    <p>{`This is just a native `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/select"
      }}>{`select`}</a>{`, so the interactions will match the paradigms of the user's OS and browser.`}</p>
    <h2>{`Accessibility`}</h2>
    <p>{`Select placeholders cannot be used in place of labels and should be used sparingly. They also should not convey critical information.`}</p>
    <p>{`Select options should not be prefixed with emoji, as that disrupts typeahead behavior.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="Select" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "../ui-patterns/forms/overview"
        }}>{`Forms`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/action-menu"
        }}>{`Action menu`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/autocomplete"
        }}>{`Autocomplete`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/checkbox-group"
        }}>{`Checkbox group`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/radio-group"
        }}>{`Radio group`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      